import { Articles } from "@r8er/components/marketing/articles";
import { Benefits } from "@r8er/components/marketing/benefits";
import { Button } from "@r8er/components/marketing/button";
import { Categories } from "@r8er/components/marketing/categories";
import { Cta } from "@r8er/components/marketing/cta";
import { SocialIcons } from "@r8er/components/marketing/icons";
import { Layout } from "@r8er/components/marketing/layout";
import { Newsletter } from "@r8er/components/marketing/newsletter";
import { Slide, Slider } from "@r8er/components/marketing/slider";
import { SliderHero as Hero } from "@r8er/components/marketing/sliderHero";
import { getAllArticles } from "@r8er/lib/cms";
import Head from "next/head";

export default function Home({ articles }) {
  return (
    <div>
      <Head>
        <title>
          Digital evaluation platform for disruptive healthcare - R8ER
        </title>
        <link rel="icon" href="/favicon.png" />
      </Head>

      <Layout>
        <Slider>
          <Slide>
            <Hero
              title={
                <>
                  The world's first,
                  <br />
                  professionally vetted,
                  <br />
                  digital evaluation platform
                  <br />
                  for disruptive healthcare.
                </>
              }
              img="/home-hero-1.png"
            >
              <p style={{ position: "relative", zIndex: 1 }}>
                <Button buttonText="Find Out More" buttonHref="/about" />
              </p>
              <SocialIcons />
            </Hero>
          </Slide>
          <Slide>
            <Hero
              title={
                <>
                  A bridge between
                  <br /> credible, vetted
                  <br /> healthcare-focused
                  <br /> entrepreneurs and
                  <br /> sophisticated investors.
                </>
              }
              img="/home-hero-2.png"
            >
              <p style={{ position: "relative", zIndex: 1 }}>
                <Button buttonText="Find Out More" buttonHref="/about" isBlue />
              </p>
              <SocialIcons />
            </Hero>
          </Slide>
          <Slide>
            <Hero
              title={
                <>
                  Bringing positive
                  <br /> change to medicine
                  <br /> through new ideas that will
                  <br /> change lives for the better.
                </>
              }
              img="/home-hero-3.png"
            >
              <p style={{ position: "relative", zIndex: 1 }}>
                <Button buttonText="Find Out More" buttonHref="/about" />
              </p>
              <SocialIcons />
            </Hero>
          </Slide>
        </Slider>

        <Benefits />
        <Categories
          title="Healthcare Categories"
          description="The future of healthcare will include a broad range of skills and bright ideas, many of which will fall under the categories below."
          limit={3}
        />

        <Cta
          title="Start Your R8ER Journey Today"
          description="If you are are looking for investment or looking to invest, sign up to R8ER today."
          buttonText="Get Started"
          buttonHref="/auth/signup"
        />

        {articles.length ? (
          <Articles
            title="Latest News &amp; Updates"
            articles={articles}
            limit={3}
          />
        ) : (
          <>
            <br />
            <br />
            <br />
            <br />
          </>
        )}

        <Newsletter />
      </Layout>
    </div>
  );
}

export async function getStaticProps(context) {
  const blogArticles = await getAllArticles();

  return {
    props: {
      articles: blogArticles,
    },
  };
}
